<template>
  <div style="background-color: #F8F7F3;">
    <v-carousel
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item>
        <v-sheet
          height="100%"
        >
          <v-row
            class="fill-height pa-4"
            align="center"
            justify="center"
          >
            <v-col v-if="$vuetify.breakpoint.smAndUp" xl="1" lg="1" md="1" sm="1" cols="1"></v-col>
            <v-col xl="5" lg="5" md="5" sm="10" cols="10">            
              <div class="text-h3 transition-swing mb-4 mt-4">
                Compagnon :
              </div>
              <div class="text-h5 transition-swing mb-4 mt-4">
                La solution de pilotage de votre téléphonie d’entreprise
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" xl="5" lg="5" md="5" sm="5" cols="5">
              <img :src="image_1" style="max-width: 70%" />
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex mt-10">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="text-h2 transition-swing mb-4 mt-4 text-center">
          Les services
        </div>
        <ColImgText right :img="image_2" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Gagnez en efficacité
            </div>
            <div class="text-subtitle-1 transition-swing mt-4">
              <b>Un clic pour un appel :</b>
            </div>
            <div class="text-subtitle-1 transition-swing mb-4">
              Ne perdez pas de temps à écrire un numéro mais lancez un appel directement grâce à l’aide à la numérotation en sélectionnant un numéro depuis partout ou en contactant vos collègues d’un simple clic depuis Compagnon.
            </div>
            <div class="text-subtitle-1 transition-swing mt-4">
              <b>La supervision disponible même en 2G :</b>
            </div>
            <div class="text-subtitle-1 transition-swing mb-4">
              Vous pouvez savoir si un collaborateur est disponible à tout moment depuis Compagnon, de même que vos collaborateurs sont avertis de votre disponibilité ou votre occupation.
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="image_3" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Retrouvez vos annuaires d’entreprise
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Avec votre annuaire d'entreprise directement intégré à votre Compagnon, vous gagnez un temps d'avance et vous restez toujours informé sur les interlocuteurs de vos appels et vos collaborateurs de travail grâce à la synchronisation avec vos contacts d’entreprise et sur votre mobile. 
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <ul>
                <li>Les contacts de votre entreprise et de votre smartphone intégrés à Compagnon</li>
                <li>Accédez à la fiche de coordonnées de vos contacts</li>
                <li>Lancez un appel en un clic</li>
                <li>Gérez vos groupes de contacts selon vos préférences</li>
              </ul>
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText right :img="image_4" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Une visibilité totale avec votre journal d’appels
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Avec Compagnon, vous êtes toujours au courant des personnes qui ont essayé de vous contacter ou des discussions téléphoniques passées.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Tout est enregistré de façon claire et précise sur votre Compagnon, vous ne perdez aucune trace de vos appels entrants, sortants, non répondus ou manqués.
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="image_5" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Gérez votre messagerie vocale visuelle
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Tous vos messages vocaux reliés dans votre messagerie vocale visuelle sur votre Compagnon avec les informations détaillées sur votre interlocuteur.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Personnalisez vos annonces vocales et soignez votre image professionnelle en informant vos clients.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <ul>
                <li>Messagerie vocale couplée à votre annuaire d'entreprise</li>
                <li>Ecoutez vos messages vocaux ou téléchargez-les </li>
                <li>Paramétrez vos annonces vocales en quelques clics</li>
              </ul>
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText right :img="image_7" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Tchatez avec vos collègues à tout moment
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Pour vous permettre de gagner en efficacité et de contacter plus rapidement vos collègues de travail tout en ayant la visibilité sur leur disponibilité, Compagnon dispose d’un tchat instantané vous permettant de joindre vos collègues par message. 
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="image_6" :imgMaxWidth="'50%'">
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Planifiez vos renvois d’appels Facilement
            </div>
            <div class="text-subtitle-1 transition-swing mt-4">
              Une seule interface simple pour gérer tous vos renvois d'appels. 
            </div>
            <div class="text-subtitle-1 transition-swing mt-2">
              Informez vos clients, collègues, ou partenaires lorsque vous êtes disponible, occupé et orientez-les de manière efficace selon votre disponibilité pour améliorer leur expérience client et vous concentrer sur votre business.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-2">
              <ul>
                <li>Renvois "sans condition"</li>
                <li>Renvois "avec condition"</li>
                <li>Sur non-réponse </li>
                <li>Sur occupation </li>
                <li>Sur indisponibilité</li>
              </ul>
            </div>
          </template>
        </ColImgText>
      </v-col>
      <v-col cols="1"></v-col>
    </div>
  </div>
</template>

<script>

import ColImgText from '../../../components/App/ColImgText';

import image1 from '../../../assets/images/compagnon/Image1.png'
import image2 from '../../../assets/images/compagnon/Image2.png'
import image3 from '../../../assets/images/compagnon/Image3.png'
import image4 from '../../../assets/images/compagnon/Image4.png'
import image5 from '../../../assets/images/compagnon/Image5.png'
import image6 from '../../../assets/images/compagnon/Image6.png'
import image7 from '../../../assets/images/compagnon/Image7.png'

export default {
  name: 'Presentation',

  components: { ColImgText },

  data () {
    return {
      image_1: image1,
      image_2: image2,
      image_3: image3,
      image_4: image4,
      image_5: image5,
      image_6: image6,
      image_7: image7,
    }
  },
};
</script>