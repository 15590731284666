<template>
  <div style="background-color: #F8F7F3;">
    <v-carousel
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item>
        <v-sheet
          height="100%"
        >
          <v-row
            class="fill-height pa-4"
            align="center"
            justify="center"
          >
            <v-col v-if="$vuetify.breakpoint.smAndUp" xl="1" lg="1" md="1" sm="1" cols="1"></v-col>
            <v-col xl="5" lg="5" md="5" sm="10" cols="10">            
              <div class="text-h3 transition-swing mb-4 mt-4">
                Convergence :
              </div>
              <div class="text-h5 transition-swing mb-4 mt-4">
                Fusionnez votre téléphonie fixe avec votre téléphonie mobile
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" xl="5" lg="5" md="5" sm="5" cols="5">
              <img :src="image_1" style="max-width: 70%" />
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex mt-10">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="text-h2 transition-swing mt-4 text-center">
          Les services
        </div>
        <div class="text-h3 transition-swing mb-4 mt-2 text-center">
          Reprenez la main sur les communications téléphoniques de votre entreprise :
        </div>
        <ColImgText right :img="image_2" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Embarquez votre téléphonie fixe dans vos mobiles :
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Présentez votre numéro de mobile ou de fixe lors d’un appel sortant.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Vos collaborateurs voient quand vous êtes en ligne sur votre mobile comme sur votre fixe.
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="image_3" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Les appels vers votre mobile sont aussi présentés sur votre fixe :
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Mise en attente sur la musique d’attente de votre société.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Transferts internes ou externes.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Appelez à partir de votre fixe avec votre 06 sans rayonnement.
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText right :img="image_4" :imgMaxWidth="'50%'" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Transférez un appel à partir de votre mobile comme sur un fixe (accessible sur tous les téléphones).
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Supervision des collaborateurs grâce à l’application Compagnon.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Historique de tous les appels entrant et sortants fixes et mobiles dans l’extranet, avec la possibilité d’enregistrer les communications.
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="image_5" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Double SIM pour plus de polyvalence 2 numéros pour un seul Mobile:
            </div>
            <div class="text-subtitle-1 transition-swing mt-4">
              Séparez votre vie professionnelle de votre vie personnelle et changer votre numéro de mobile
            </div>
            <div class="text-subtitle-1 transition-swing mb-4">
              À l’aide d’une simple pression sur un bouton.
            </div>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Améliorez votre trafic d’appel :
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Orientez vos flux d’appels de manière fluide selon votre disponibilité ou vos interlocuteurs pour améliorer leur expérience client et vous concentrer sur votre business.
            </div>
          </template>
        </ColImgText>
      </v-col>
      <v-col cols="1"></v-col>
    </div>
  </div>
</template>

<script>

import ColImgText from '../../../components/App/ColImgText';

import image1 from '../../../assets/images/convergence/Image1.png'
import image2 from '../../../assets/images/convergence/Image2.jpg'
import image3 from '../../../assets/images/convergence/Image3.jpg'
import image4 from '../../../assets/images/convergence/Image4.png'
import image5 from '../../../assets/images/convergence/Image5.png'

export default {
  name: 'Presentation',

  components: { ColImgText },

  data () {
    return {
      image_1: image1,
      image_2: image2,
      image_3: image3,
      image_4: image4,
      image_5: image5,
    }
  },
};
</script>