<template>
  <div style="background-color: #F8F7F3;">
    <v-carousel
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item>
        <v-sheet
          height="100%"
        >
          <v-row
            class="fill-height pa-4"
            align="center"
            justify="center"
          >
            <v-col v-if="$vuetify.breakpoint.smAndUp" xl="1" lg="1" md="1" sm="1" cols="1"></v-col>
            <v-col xl="5" lg="5" md="5" sm="10" cols="10">            
              <div class="text-h3 transition-swing mb-4 mt-4">
                Votre portail de Gestion unifiée.
              </div>
              <div class="text-subtitle-1 transition-swing mb-4 mt-4">
                <ul>
                  <li>La configuration de votre solution en quelques clics.</li>
                  <li>Adaptation à vos besoins et à votre évolution.</li>
                  <li>Possibilités avancées de gestion et de pilotage en temps réel.</li>
                  <li>Interface ergonomique et simple d’utilisation.</li>
                  <li>Compatible PC, tablettes et mobiles.</li>
                </ul>
              </div>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" xl="5" lg="5" md="5" sm="5" cols="5">
              <img :src="customer_0" style="max-width: 70%" />
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div class="d-flex mt-10">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="text-h2 transition-swing mb-4 mt-4 text-center">
          Les services
        </div>
        <ColImgText right :img="customer_1" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Retrouvez votre journal d’appels
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Le journal permet la visualisation détaillée des appels entrants et sortant de vos collaborateurs.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Conservez tout l’historique des appels de votre société.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Accédez à un affichage encore plus précis via la visualisation complète d’un appel répondu.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Déterminez La qualité de votre accueil téléphonique par la visualisation des équipements qui ont sonné et du délai de sonnerie associé.
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="customer_2" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Mesurez et analysez vos flux d’appels
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              En analysant le nombre d’appels effectués par appareil, par période, par localisation géographique, par durée etc…
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Vous pourrez optimiserez la gestion de votre entreprise en fonction de vos volumes d’appels.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <ul>
                <li>Contrôlez vos consommations sortantes.</li>
                <li>Interprétez vos pourcentages d’appels et la durée moyenne de ceux-ci.</li>
                <li>Organisez mieux vos collaborateurs selon les jours de haute ou basse fréquentation d’appels entrants grâce au détail par jour et par semaine.</li>
              </ul>
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText right :img="customer_3" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Configurez vos postes facilement
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Vous pouvez paramétrer chaque poste en fonction de votre croissance d’entreprise et des besoins de vos collaborateurs.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Personnalisez les touches raccourcis de vos postes téléphoniques pour composer un numéro interne ou externe.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Programmez entièrement votre flotte téléphonique
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Choisissez les différents annuaires à intégrer (Entreprise, site, personnel etc…)
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText :img="customer_4" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Facilitez la gestion de votre parc de lignes
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              Administrez aisément votre téléphonie en créant des groupes. Par exemple, assignez un groupe à chacun de vos sites. De plus vous avez la possibilité de gérer les accès de vos collaborateurs à l’Extranet.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <ul>
                <li>Gérez vos utilisateurs et leurs accès.</li>
                <li>Administrez vos utilisateurs à des groupes par site ou par pôle par exemple.</li>
              </ul>
            </div>
          </template>
        </ColImgText>
        <!---->
        <ColImgText right :img="customer_5" >
          <template v-slot:content>
            <div class="text-h4 transition-swing mb-4 mt-4">
              Personnalisez votre accueil téléphonique
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
            Bien accueillir, faire patienter dans de bonnes conditions, informer de votre disponibilité, autant d’éléments qui sont une pierre de l’édifice de votre « Image de Marque ». Tous ces éléments peuvent être contrôlés et planifiés depuis l’Extranet.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <b>Mode d’Accueil :</b> Basculez votre accueil téléphonique en fonction de différents scénarii (Jours fériés, astreinte etc…)
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <b>Horaires d’accueil :</b> indiquez vos horaires d’ouverture et de fermeture.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <b>Bibliothèque audio :</b> Gérez votre musique d’attente.
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              <b>Jours fériés :</b> Enregistrez les jours où votre entreprise ferme.
            </div>
          </template>
        </ColImgText>
      </v-col>
      <v-col cols="1"></v-col>
    </div>
  </div>
</template>

<script>

import ColImgText from '../../../components/App/ColImgText';

import customer_0 from '../../../assets/images/customer-0.png'
import customer_1 from '../../../assets/images/customer-1.png'
import customer_2 from '../../../assets/images/customer-2.png'
import customer_3 from '../../../assets/images/customer-3.png'
import customer_4 from '../../../assets/images/customer-4.png'
import customer_5 from '../../../assets/images/customer-5.png'

export default {
  name: 'Presentation',

  components: { ColImgText },

  data () {
    return {
      customer_0: customer_0,
      customer_1: customer_1,
      customer_2: customer_2,
      customer_3: customer_3,
      customer_4: customer_4,
      customer_5: customer_5,
    }
  },
};
</script>