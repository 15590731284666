import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      validationLoading: false,
      validation: null
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'app/getUser'
    })
  },
  methods: {
    validate: function() {
      this.validationLoading = true;

      if (this.getUser) {
        this.globalStore.api.PhoneSettings.getDIDNumbers(this.getUser.id)
          .then((response) => {
            if (response.success) {
              this.validation = true;
            }
            else {
              this.validation = false;
            }
          })
          .catch(() => { 
            this.validation = false;
          });
      }
      else {
        this.validation = false;
      }
    }
  }
}