<template>
  <div style="background-color: #F8F7F3;">
    <HomeCarousel></HomeCarousel>
    <div class="d-flex mt-10">
      <v-col xl="2" lg="2" md="1" cols="1"></v-col>
      <v-col xl="8" lg="8" md="10" cols="10">
        <div class="text-h2 transition-swing mb-4 mt-4">
          Comment pouvons-nous vous aider ?
        </div>
        <div class="text-subtitle-1 transition-swing mb-4 mt-4">
          Vous trouverez ci-dessous toutes les rubriques d’aide concernant l’utilisation de vos solutions OcciCom.
        </div>
        <HomeCardGroup></HomeCardGroup>
      </v-col>
      <v-col xl="2" lg="2" md="1" cols="1"></v-col>
    </div>
  </div>
</template>

<script>
import HomeCarousel from './components/HomeCarousel.vue';
import HomeCardGroup from './components/HomeCardGroup.vue';
export default {
  components: { HomeCarousel, HomeCardGroup },
  name: 'Home',

  data () {
    return {}
  },
};
</script>