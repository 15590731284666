<template>
  <div class="w-100">
    <v-app-bar height="10px" color="#fee400" app></v-app-bar>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {  },
  name: 'Marketing',

  data () {
    return {
      
    }
  },
};
</script>