<template>
  <div class="d-flex pt-4 h-100 center-xy">
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"></v-col>
    <v-col xl="8" lg="8" md="8" sm="12" cols="12" class="mt-4">
      <v-card
        class="mx-auto mt-4"
        max-width="400"
      >
        <v-img
          src="@/assets/logo.png"
        >
        </v-img>
        <v-alert v-if="error != false" type="error" class="my-2">
          Impossible de vous authentifier
        </v-alert>
        <v-card-text class="text--primary">
          <v-text-field
            v-model="email"
            name="email"
            type="email"
            outlined
            label="Adresse e-mail"
            prepend-inner-icon="mdi-account"
            hide-details=true
            :disabled="loading"
            class="mb-4"
            @keyup="onEmailChanged"
            v-on:keyup.native.enter="login"
          ></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            outlined
            :label="useOTP ? 'Code OTP' : 'Mot de passe'"
            prepend-inner-icon="mdi-lock"
            hide-details=true
            :disabled="loading"
            v-on:keyup.native.enter="login"
          ></v-text-field>
          <v-switch
            class="mt-0 mb-4"
            v-model="useOTP"
            label="Utiliser le code OTP"
            color="success"
            hide-details
            :disabled="loading"
          ></v-switch>
          <v-btn :loading="loading" outlined block color="primary" @click="login">Connexion</v-btn>
        </v-card-text>
      </v-card>
      <v-card
        class="mx-auto mt-4"
        width="400"
      >
        <v-btn to="/otp" disabled outlined block color="primary">Recevoir le code OTP</v-btn>
      </v-card>
    </v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2"></v-col>
  </div>
</template>

<script>
export default {
  name: 'Login',

  data () {
    return {
      email: localStorage.getItem('lastEmail') ? localStorage.getItem('lastEmail') : null,
      password: null,
      error: false,
      loading: false,
      useOTP: false
    }
  },
  methods: {
    onEmailChanged: function() {
      localStorage.setItem('lastEmail', this.email);
    },
    // Demande de token d'authentification pour pouvoir utiliser l'application
    login: function() {
      this.onEmailChanged();

      this.loading = true;
      this.error = false;

      this.globalStore.api.login(this.email, (this.useOTP ? this.password : null), (this.useOTP ? null : this.password))
        .then((response) => {
          if (response.success) {
            const user = {};
            
            user.token = response.token;
            user.id = response.user_id;

            user.credential = {
              user: this.email,
              useOTP: this.useOTP
            };

            this.$store.dispatch('app/login', user)
            this.$router.push('/')
          }
          else {
            throw new Error();
          }
        })
        .catch(() => { 
          this.error = true;
        })
        .finally(() => { this.loading = false; });
    }
  }
};
</script>