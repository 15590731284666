import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify);

// Erreur de ma part pour l'instant, il va falloir modifier la langue des tables en fonctions du choix de l'utilisateur
Vue.component('v-data-table', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'fr'
    },
  },
})

export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
      options: {
        customProperties: true
      },
      themes: {
        light: {
          primary: '#df0e1d',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#b71c1c',
          loading: '#000000'
        },
        dark: {
          primary: '#ffeb3b',
          secondary: '#b0bec5',
          accent: '#8c9eff',
          error: '#b71c1c',
          loading: '#ffeb3b'
        }
      }
    },
});
