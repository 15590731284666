/**
 * Vue fonctionne mieux avec un principe de routage d'URL
 * Pour faire simple, chaque chemin va être lié a une vue.
 * Il est possible de faire de la transmission d'info par args comme un vrai router a la Laravel/ExpressJS bref comme chaque framework
 * Il est aussi possible d'intercepter le changement de route pour pouvoir executer un payload avant/après chaque routage
 * Par exemple dans ce code, la vérification de l'auth avant chaque changement avec le metatag "guest"
 * Si jamais, c'est possible de faire un drawer pour gérer les routes dans plusieurs fichiers mais je trouve ça contreproductif
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'

import Customer from '../views/CustomerSpace'
import Compagnon from '../views/Compagnon'
import Convergence from '../views/Convergence'

import Help from '../views/Help'

import m28092022 from '../views/Marketing/m28092022'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    components: {
      default: Home
    },
  },
  {
    path: '/espace_client',
    components: {
      default: Customer
    }
  },
  {
    path: '/convergence',
    components: {
      default: Convergence
    }
  },
  {
    path: '/compagnon',
    components: {
      default: Compagnon
    }
  },
  {
    path: '/login',
    components: {
      default: Login
    },
  },
  {
    path: '/aide',
    components: {
      default: Help
    },
  },
  {
    path: '/m28092022',
    components: {
      default: m28092022
    },
    meta: {
      marketing: true
    }
  }
]

const router = new VueRouter({
  routes
})

export default router