/**
 * Fichier principal qui permet de setup l'application et les différentes lib
 * C'est le premier fichier que VUE ouvre
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Machine à états/store
import Vuex from 'vuex'

/**
 * Ici j'importe des plugins et pas directement la lib,
 * ça me permet de la configurer dans un fichier distinct
 */
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'

import env from './config/index';

import OccicomAPI from 'occicom-w3tel-api-wrapper'
import Axios from 'axios'

Vue.prototype.$http = Axios;

class GlobalStore {
  constructor() {
    this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : { token: null, id: null },
    this.version = process.env.VERSION || '0',
    this.api = new OccicomAPI("v1", this.user.token, this.user.id)
  }
}

const globalStore = new GlobalStore();

Vue.config.productionTip = false;

Vue.use(Vuex);

Vue.use(env);

Vue.mixin({
  data() {
    return {
      globalStore
    }
  }
});

// Création de l'application avec ses plugins
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
