<template>
  <v-card class="d-flex">
    <v-col xs="12" sm="12" md="8" lg="8" xl="8" class="pa-8" style="height: 100vh; overflow: auto;">
      <div class="ma-4">
        <div class="text-h3 text-center mb-6">
          Inscrivez vous pour la journée portes ouvertes
        </div>
        <div class="center-x mb-6">
          <img src="./assets/images/LOGO_LMB.jpg" style="max-width: 100%" class="center-xy px-2 py-2" />
        </div>
        <div class="text-h5 text-center mt-2">
          Solutions d'impressions, Ecrans interactifs, Logiciel de dématérialisation
        </div>
        <v-row class="mb-6">
          <v-col cols="4" class="center-xy">
            <div class="center-x">
              <img src="./assets/images/Logo_OFFICIEL_2021.png" style="max-width: 100%" class="center-xy px-2 py-2" />
            </div>
          </v-col>
          <v-col cols="4" class="center-xy">
            <div class="center-x">
              <img src="./assets/images/web-premiere-logos-long.png" style="max-width: 100%" class="center-xy px-2 py-2" />
            </div>
          </v-col>
          <v-col cols="4" class="center-xy">
            <div class="center-x">
              <img src="./assets/images/afigec.png" style="max-width: 50%" class="center-xy px-2 py-2" />
            </div>
          </v-col>
        </v-row>
        <div class="text-h4 text-center">
          En loge au Stade Pierre-Fabre, Rue de Bisseous, 81100 Castres
        </div>
        <div class="text-h5 text-center mb-8">
          Venez découvrir nos nouveautés, nos services lors de notre journée portes ouvertes le 8 novembre 2022 en loge au Stade Pierre-Fabre.
        </div>
        <div class="center-x mb-8">
          <img src="./assets/images/JPO_LMB.png" style="max-width: 70%" class="center-xy px-2 py-2" />
        </div>
        <div class="text-h4 text-center">
          Venez rencontrer et découvrir nos entreprises partenaires :
        </div>
        <div class="center-x mt-8">
          <img src="./assets/images/Logo_OFFICIEL_2021.png" style="max-width: 50%" class="center-xy px-2 py-2" />
        </div>
        <div class="text-h5 text-center mt-2 mb-8">
          Téléphonie d'entreprise (fixe, mobiles), intégration informatique, réseaux, internet & fibre, accompagnement et service réactif, maintenance.
        </div>
        <div class="center-x mt-4">
          <img src="./assets/images/web-premiere-logos-long.png" style="max-width: 50%" class="center-xy px-2 py-2" />
        </div>
        <div class="text-h5 text-center mt-2 mb-8">
          Création de site Internet, applications mobile, cloud, ERP CRM.
        </div>
        <div class="center-x mt-4">
          <img src="./assets/images/afigec.png" style="max-width: 25%" class="center-xy px-2 py-2" />
        </div>
        <div class="text-h5 text-center mt-2 mb-8">
          Installation de parc informatique, Déploiement des serveurs et réseaux, Infogérance, Sécurité de vos infrastructures, Assistance et Maintenance.
        </div>
      </div>
    </v-col>
    <v-col xs="12" sm="12" md="4" lg="4" xl="4">
      <v-card class="pa-4">
        <div class="text-h4 text-center mt-8 mb-8">
          Formulaire d'inscription
        </div>
        <v-text-field v-model="entreprise" light solo label="Nom de la société"></v-text-field>
        <v-text-field v-model="nom" name="name" light solo label="Nom"></v-text-field>
        <v-text-field v-model="prenom" name="firstName" light solo label="Prénom"></v-text-field>
        
        <v-select
          v-model="time"
          :items="times"
          light solo
          label="Votre arrivée"
        ></v-select>
        <v-text-field v-model="tel" name="phone" type="tel" light solo label="Téléphone"></v-text-field>
        <v-checkbox v-model="checkbox">
          <template v-slot:label>
            <div>
              J'ai lu et j'accepte la
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://www.occicom.fr/"
                    @click.stop
                    v-on="on"
                  >
                    politique de confidentialité d'Occicom
                  </a>
                </template>
                Ouvrir dans un autre onglet
              </v-tooltip>
              en matière de protection des données à caractère personnel.
            </div>
          </template>
        </v-checkbox>
        <v-btn :disabled="!isValid" :loading="loading" block @click="send">S'inscrire</v-btn>
        <v-alert
          v-if="error"
          class="mt-4"
          type="error"
        >
          Il y a eu une erreur, veuillez réessayer plus tard
        </v-alert>
        <v-alert
          v-if="success"
          class="mt-4"
          type="success"
        >
          Sauvegarde réussite, merci de votre confiance !
        </v-alert>
      </v-card>
    </v-col>
  </v-card>
</template>

<script>
export default {
  components: {  },
  name: 'm28092022',

  data () {
    return {
      prenom: null,
      nom: null,
      tel: null,
      entreprise: null,
      time: null,
      times: [
        "Avant 11h",
        "11h à 12h",
        "12h à 14h",
        "Après 14h"
      ],
      checkbox: false,
      loading: false,
      error: false,
      success: false
    }
  },

  computed: {
    isValid: function() {
      return (this.prenom != null && this.prenom.length > 1)
        && (this.nom != null && this.nom.length > 1)
        && (this.tel != null && this.tel.length > 1)
        && (this.entreprise != null && this.entreprise.length > 1)
        && (this.times != null)
        && this.checkbox == true
    }
  },

  methods: {
    send: function() {
      this.loading = true;
      this.error = this.success = false;
      
      this.$http.post(this.$env.api.host + "/formsData", {
        form: 1,
        title: "Porte ouverte 08/11/2022",
        value: JSON.stringify(this.getValue())
      })
      .then(() => {
        this.loading = false;
        this.success = true;
        this.reset();
      })
      .catch((error) => {
        console.error(error);
        this.error = true;
        this.loading = false;
      });
    },
    getValue: function() {
      return {
        prenom: this.prenom,
        nom: this.nom,
        tel: this.tel,
        time: this.time,
        entreprise: this.entreprise,
        checkbox: this.checkbox
      }
    },
    reset: function() {
      this.prenom = null;
      this.nom = null;
      this.tel = null;
      this.entreprise = null;
      this.time = null;
      this.checkbox = false;
    }
  }
};
</script>