<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : ''">
    <v-col v-if="!right || !$vuetify.breakpoint.mdAndUp" xl="6" lg="6" md="6" sm="12" cols="12" class="center-xy">
      <img :src="img" :style="'max-width:' + imgMaxWidth" />
    </v-col>
    <v-col xl="6" lg="6" md="6" sm="12" cols="12" :class="$vuetify.breakpoint.mdAndUp ? right ? 'text-right center-y' : 'center-y' : ''">
      <slot name="content"></slot>
    </v-col>
    <v-col v-if="right && $vuetify.breakpoint.mdAndUp" xl="6" lg="6" md="6" sm="12" cols="12" class="center-xy">
      <img :src="img" :style="'max-width:' + imgMaxWidth" />
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'ColImgText',

  props: {
    img: {
      type: String,
      default: ""
    },
    right: {
      type: Boolean,
      default: false
    },
    imgMaxWidth: {
      type: String,
      default: '100%'
    }
  },

  data () {
    return {

    }
  },
};
</script>