<template>
  <v-app>
    <v-app-bar
      v-if="!isMarketing && $vuetify.breakpoint.mobile"
      color="#DF101E"
      dark
      app
    >
      <v-app-bar-nav-icon @click="menu = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <img src="./assets/logo.png" style="max-height: 100%" />
    </v-app-bar>
    <Navigation v-if="!isMarketing && $vuetify.breakpoint.mobile" />

    <v-main>
      <div v-if="!isMarketing" class="app h-100">
        <div v-if="!$vuetify.breakpoint.mobile" class="nav h-100">
          <Navigation />
        </div>
        <div :class="!$vuetify.breakpoint.mobile ? 'content' : 'content mobile'">
          <router-view></router-view>
          <div v-if="$route.path != '/login'" class="mt-4">
            <div class="d-flex">
              <v-col v-if="$vuetify.breakpoint.smAndUp" xl="2" lg="2" md="1" sm="1" cols="1"></v-col>
              <v-col xl="8" lg="8" md="10" sm="10" cols="12">
                <v-card class="mx-3 pa-4">
                  <div :class="$vuetify.breakpoint.smAndUp ? 'd-flex' : ''">
                    <v-col xl="8" lg="8" md="8" sm="8" cols="12">
                      <div class="text-h4 transition-swing mb-4 mt-4">
                        Vous avez une demande spécifique ?
                      </div>
                      <div class="text-subtitle-1 transition-swing mb-4 mt-4">
                        Besoin d’aide pour une autre demande technique ? Contactez-nous et nous vous garantissons de vous apporter l’aide qu’il vous faut sous 24h.
                      </div>
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="4" cols="12" class="center-x">
                      <v-btn x-large block color="#DF101E" class="white--text" to="/aide">être rappelé</v-btn>
                    </v-col>
                  </div>
                </v-card>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.smAndUp" xl="2" lg="2" md="1" sm="1" cols="1"></v-col>
            </div>
            <div style="display:flex; padding: 20px;">
              <v-col v-if="$vuetify.breakpoint.smAndUp" xl="2" lg="2" md="1" cols="1"></v-col>
              <v-col xl="8" lg="8" md="10" sm="10" cols="12">
                <img src="./assets/logo.png" style="max-width: 70%" />
                <p class="my-4">Votre Opérateur Télécom Pro Local et Réactif !</p>

                <v-row class="mb-2">
                  <v-btn
                    v-for="(social, i) in socials"
                    :key="i"
                    :color="social.color"
                    class="white--text"
                    fab
                    icon
                    small
                  >
                    <v-icon>{{ social.icon }}</v-icon>
                  </v-btn>
                </v-row>

                @2022 OcciCom. Tous droits réservés.
              </v-col>
            </div>
            <div style="display: flex; flex-wrap: wrap; background-color: #DF101E; color: white; padding: 20px;">
              <v-col xl="3" lg="3" md="3" sm="3" cols="6">
                <h3>OcciCom Tarn</h3>
                <p>1 Rue Georges Charpak, Bat A</p>
                <p>81290 LABRUGUIERE</p>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="3" cols="6">
                <h3>OcciCom Aveyron</h3>
                <p>Parc d'activités de Cassagnettes</p>
                <p>12510 OLEMPS</p>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="3" cols="6">
                <h3>OcciCom Cantal</h3>
                <p>14 avenue du Garric</p>
                <p>15013 AURILLAC</p>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="3" cols="6">
                <h3>OcciCom Tarn et Garonne</h3>
                <p>1935 Avenue de Fonneuve</p>
                <p>82000 Montauban</p>
              </v-col>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="app h-100">
        <Marketing />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import Navigation from './components/App/Navigation';
import Marketing from './views/Marketing';

export default {
  name: 'App',

  components: { Navigation, Marketing },

  computed: {
    ...mapGetters({
      getUser: 'app/getUser',
      getMenu: 'app/getMenu'
    }),

    isLogged: function() {
      return this.getUser ? this.getUser.token != null ? true : false : false;
    },

    menu: {
      get() {
        return this.getMenu;
      },
      set(value) {
        this.$store.commit('app/setMenu', value)
      }
    },

    isMarketing: function() {
      return this.$route.meta ? this.$route.meta.marketing : false;
    }
  },

  methods: {

  },
  
  data () {
    return {
      socials: [
        {
          icon: 'mdi-facebook',
          color: 'indigo',
        },
        {
          icon: 'mdi-twitter',
          color: 'cyan',
        },
        {
          icon: 'mdi-linkedin',
          color: 'indigo',
        },
        {
          icon: 'mdi-instagram',
          color: 'red lighten-1',
        },
      ]
    }
  },

  mounted: function() {
    
  }
};
</script>
<style>
  html {
    overflow: hidden;
  }

  .center-x {    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-y {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center-y-parent {
    position: relative;
  }

  .center-y-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .center-xy {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }

  p {
    margin-bottom: 0 !important;
  }

  .app {
    display: flex;
  }

  .nav {
    width: 300px;
  }

  .content {
    flex: 1;
    overflow-y: scroll;
    position: absolute;
    top: 0px;
    left: 300px;
    bottom: 0;
    right: 0;
  }

  .mobile {
    left: 0px;
  }
</style>
