<template>
  <div style="background-color: #F8F7F3;">
    <div class="d-flex">
      <v-col xl="2" lg="2" md="1" cols="1"></v-col>
      <v-col xl="8" lg="8" md="10" cols="10">
        <div class="text-h2 transition-swing mb-4 mt-4">
          Vous souhaitez avoir une assistance technique ?
        </div>
        <div class="text-subtitle-1 transition-swing mb-4 mt-4">
          Indiquez-nous votre problématique et nous rappelerons dans un délais de 24 heures :
        </div>
        <div>
          <v-text-field
              v-model="user_title"
              label="Nom et prénom"
              outlined
              required
          ></v-text-field>
          <v-text-field
              v-model="user_societe"
              label="Société"
              outlined
              required
          ></v-text-field>
          <v-text-field
              v-model="user_email"
              label="E-mail"
              outlined
              required
          ></v-text-field>
          <v-text-field
              v-model="user_phone"
              type="tel"
              label="Téléphone"
              outlined
              required
          ></v-text-field>
          <v-textarea
              v-model="description"
              auto-grow
              outlined
              name="Décrivez nous votre problème :"
              label="Décrivez nous votre problème :"
          ></v-textarea>
          <vue-hcaptcha @verify="captchaVerified" :sitekey="$env.hcaptcha.pk"></vue-hcaptcha>
          <v-btn :loading="loading" color="green"  block @click="send">Envoyer votre demande</v-btn>
          <v-alert
            v-if="error != null"
            class="mt-4"
            type="error"
          >
            {{ error }}
          </v-alert>
          <v-alert
            v-if="ok != null"
            class="mt-4"
            type="success"
          >
            {{ ok }}
          </v-alert>
        </div>
      </v-col>
      <v-col xl="2" lg="2" md="1" cols="1"></v-col>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: 'Help',

  components: { VueHcaptcha },

  data () {
    return {
      user_email: null,
      user_phone: null,
      user_title: null,
      user_societe: null,
      description: null,
      token: null,
      loading: false,
      error: null,
      ok: null
    }
  },
  methods: {
    captchaVerified: function(token) {
      console.log(token);
      this.token = token;
    },
    send: function() {
      this.error = this.ok = null;

      if (this.user_email == null || this.user_email.length < 5 || !this.validateEmail(this.user_email)) {
        this.error = 'L\'adresse e-mail n\'est pas valide.'
        return
      }
      else if (this.user_phone == null || this.user_phone.length < 3) {
        this.error = 'Le numéro de téléphone n\'est pas valide.'
        return
      }
      else if (this.user_title == null || this.user_title.length < 2) {
        this.error = 'Votre nom/prénom n\'est pas valide.'
        return
      }
      else if (this.user_societe == null || this.user_societe.length < 2) {
        this.error = 'Votre nom de société n\'est pas valide.'
        return
      }
      else if (this.description == null || this.description.length < 20) {
        this.error = 'La description doit faire minimum 20 caractères.'
        return
      }
      else {
        this.loading = true;
        this.$http.post(this.$env.api.email + "/support/solution", {
          token: this.token,
          user_email: this.user_email,
          user_phone: this.user_phone,
          user_societe: this.user_societe,
          user_title: this.user_title,
          description: this.description.replaceAll("\n", "\r\n")
        })
        .then(() => {
          this.ok = 'Votre demande de support est envoyée. Merci de votre confiance.'

          this.error = null;
          this.description = null;
          this.loading = false;
        })
        .catch((error) => {
          this.error = 'Votre demande de support est en erreur.'
          console.error(error);
          this.loading = false;
        });
      }
  },
  validateEmail: function(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  }
};
</script>