<template>
  <v-item-group>
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.icon"
          xl="4" lg="6" md="6" sm="6"
          cols="12"
        >
          <v-item>
            <v-card
              class="d-flex align-center"
              dark
              height="300"
            >
              <div style="width: 100%; height: 100%; z-index: 0; position: absolute;">
                <div :style="`width: 100%; height: 100%; background-image: url(${card.img}); position: absolute; background-size: cover; background-position: 50%;`"></div>
                <div style="width: 100%; height: 100%; background-color: #000000cc; position: absolute;"></div>
              </div>
              <div class="d-flex flex-column align-center w-100">
                <div class="center-y">
                  <v-icon>{{ card.icon }}</v-icon>
                  <div class="text-h6 transition-swing text-center" style="z-index: 6;">
                    {{ card.title }}
                  </div>
                </div>
                <div class="my-4" style="z-index: 6;">
                  <div class="text-center text-subtitle-1 transition-swing">
                    {{ card.sub }}
                  </div>
                </div>
                <div class="center-y">
                  <v-btn outlined :to="card.link">J'y vais</v-btn>
                </div>
              </div>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import card_1 from '../../../assets/images/card-1.png'
import card_2 from '../../../assets/images/card-2.jpg'
import card_3 from '../../../assets/images/card-3.png'
import card_4 from '../../../assets/images/card-4.jpg'
import card_5 from '../../../assets/images/card-5.jpg'
import card_6 from '../../../assets/images/card-6.jpg'

export default {
  name: 'HomeCarousel',

  data () {
    return {
      cards: [
        {
          icon: 'mdi-face-agent',
          title: 'Espace Client',
          sub: 'Mon portail de gestion unifiée pour la configuration de ma téléphonie',
          img: card_1,
          link: '/espace_client'
        },
        {
          icon: 'mdi-cellphone-nfc',
          title: 'Convergence & Mobilité',
          sub: 'Mon offre mobilité et l\'utilisation de ma convergence fixe-mobile',
          img: card_2,
          link: '/convergence'
        },
        {
          icon: 'mdi-cellphone-link',
          title: 'Compagnon',
          sub: 'Mon appli pour piloter toute ma téléphonie d’entreprise',
          img: card_3,
          link: '/compagnon'
        },
        {
          icon: 'mdi-video',
          title: 'Tutoriels',
          sub: 'Explications vidéos détaillées pour vous apprendre à maîtriser vos solutions',
          img: card_4,
          link: '/espace_client'
        },
        {
          icon: 'mdi-wrench',
          title: 'Assistance technique',
          sub: 'Nous vous recontactons au plus vite pour vous assister à distance',
          img: card_5,
          link: '/aide'
        },
        {
          icon: 'mdi-help-box',
          title: 'FAQ',
          sub: 'Trouvez la réponse à vos demandes grâce au questions fréquemment posées',
          img: card_6,
          link: '/aide'
        }
      ]
    }
  },
};
</script>