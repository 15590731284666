/**
 * C'est dans ce fichier que sont déclarer les machines à états/store VueX
 * Pour chaque store, il faut importer son modules et le déclarer dans les modules de l'export
 */
import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app
  }
});