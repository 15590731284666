<template>
  <div style="background-color: #F8F7F3;">
    <v-tabs
      v-model="layout"
      fixed-tabs
      background-color="#DF101E"
      dark
      height="60"
    >
      <v-tab>
        Présentation
      </v-tab>
      <v-tab>
        Tutoriels
      </v-tab>
    </v-tabs>
    <Presentation v-if="layout == 0" />
    <Tutoriels v-if="layout == 1"></Tutoriels>
  </div>
</template>

<script>
import Presentation from './pages/Presentation'
import Tutoriels from './pages/Tutoriels'
export default {
  components: { Presentation, Tutoriels },
  name: 'Convergence',

  data () {
    return {
      layout: 0
    }
  },
};
</script>