// initial state
const state = () => ({
  menu: false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
});

// getters
const getters = {
  getMenu: (state) => {
    return state.menu;
  },
  getUser: (state) => {
    return state.user
  }
}

// actions
const actions = {
  login: ({ commit }, user) => {
    commit('setUser', user);
  }
}

// mutations
const mutations = {
  setMenu(state, menu) {
    state.menu = menu;
  },

  setUser(state, user) {
    state.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  },

  reset(state) {
    state.menu = false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}