<template>
  <v-navigation-drawer
    v-model="menu"
    :absolute="$vuetify.breakpoint.mobile"
    :temporary="$vuetify.breakpoint.mobile"
    :permanent="!$vuetify.breakpoint.mobile"
    dark
    style="background-color: #df101e"
    width="300px"
  >
    <img src="../../assets/logo.png" style="max-width: 100%" class="px-2 py-2" />
    <v-list>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link to="/espace_client">
        <v-list-item-icon>
          <v-icon>mdi-face-agent</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Espace client</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link to="/convergence">
        <v-list-item-icon>
          <v-icon>mdi-cellphone-nfc</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Convergence</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link to="/compagnon">
        <v-list-item-icon>
          <v-icon>mdi-cellphone-link</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Compagnon</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item link to="/aide">
        <v-list-item-icon>
          <v-icon>mdi-account-question-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Besoin d'aide ?</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isLogged" link @click="logout" to="/login">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Deconnexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-else link to="/login">
        <v-list-item-icon>
          <v-icon>mdi-account-lock-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Connexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navigation',

  computed: {
    ...mapGetters({
      getUser: 'app/getUser',
      getMenu: 'app/getMenu'
    }),
    isLogged: function() {
      return this.getUser ? this.getUser.token != null ? true : false : false;
    },

    menu: {
      get() {
        return this.getMenu;
      },
      set(value) {
        this.$store.commit('app/setMenu', value)
      }
    }
  },

  methods: {
    logout: function() {
      localStorage.clear();
      window.location.reload();
    }
  },
  
  data () {
    return {
    }
  },

  mounted: function() {
  }
};
</script>
<style>
</style>
