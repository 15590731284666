<template>
  <v-card class="pa-8 h-100 center-y">
    <div class="text-center text-h6 transition-swing">
      {{ title }}
    </div>
    <div class="text-center text-subtitle-1 transition-swing">
      {{ sub }}
    </div>
    <div class="center-x">
      <iframe :width="short ? '303' : '100%'" :height="short ? '539' : '315'" :src="link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'TutoVideo',

  props: {
    title: {
      type: String,
      default: ""
    },
    sub: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    short: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {

    }
  },
};
</script>