<template>
  <div style="background-color: #F8F7F3;">
    <v-carousel
      hide-delimiters
      hide-delimiter-background
      :show-arrows="false"
    >
      <v-carousel-item>
        <v-sheet
          height="100%"
        >
          <v-row
            class="fill-height pa-4"
            align="center"
            justify="center"
          >
            <v-col xl="3" lg="3" md="3" sm="3" cols="1"></v-col>
            <v-col xl="6" lg="6" md="6" sm="6" cols="10" class="text-center">        
              <div class="text-h3 transition-swing mb-4 mt-4">
                Tutoriels Espace client
              </div>
              <div class="text-h5 transition-swing mb-4 mt-4">
                Retrouvez nos tutoriels d'aide sur votre Espace client
              </div>
              <div class="text-subtitle-1 transition-swing mb-4 mt-4">
                Parcourez l’ensemble des tutoriels et choisissez le tutoriel vidéo qui répondra à votre problématique.
              </div>
            </v-col>
            <v-col xl="3" lg="3" md="3" sm="3" cols="1">
            </v-col>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div v-if="validationLoading && validation == null" class="text-center pb-3">
       <v-btn
          x-large
          loading
          disabled
          color="blue-grey"
          class="ma-2 white--text"
          fab
        >
          <v-icon dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn>
    </div>
    <div v-if="validationLoading && validation == true" class="d-flex mt-10">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
      <v-col xl="10" lg="10" md="10" sm="12" cols="12">
        <div class="text-h2 transition-swing mb-4 mt-4 text-center">
          Comment pouvons-nous vous aider ?
        </div>
        <v-item-group>
          <v-container>
            <v-row>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Récupération des factures" 
                    sub="Ce tutoriel vous montre comment récupérer vos factures depuis votre espace client"
                    link="https://www.youtube.com/embed/b_9ZutbcXSY" />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Modification touche rapide poste téléphonique" 
                    sub="Ce tutoriel vous montre comment modifier une ou plusieurs touche rapide de votre poste téléphonique fixe."
                    link="https://www.youtube.com/embed/T88YAoV_fYw" />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Renvois d'appels" 
                    sub="Ce tutoriel vous montre les différentes options pour renvoyer les appels de votre poste vers un autre numéro."
                    link="https://www.youtube.com/embed/_JJc2yGIHeE" />
                </v-item>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="6" cols="12">
                <v-item>
                  <TutoVideo 
                    title="Modifier les horaires d’acueil" 
                    sub="Ce tutoriel vous montre comment modifier les horaires d’acueil."
                    link="https://www.youtube.com/embed/KPGVfliR3Vw" />
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="1"></v-col>
    </div>
    <div v-if="validationLoading && validation == false" class="d-flex">
      <v-col cols="1"></v-col>
      <v-col cols="10">
        <div class="text-h4 transition-swing mb-4 mt-4 text-center">
          Vous devez être connecté à votre compte Occicom pour avoir accès à nos services
        </div>
        <v-btn block dark color="#df101e" x-large to="/login">Connexion</v-btn>
      </v-col>
      <v-col cols="1"></v-col>
    </div>
  </div>
</template>

<script>
import W3TelValidation from '../../../mixins/W3TelValidation'

import TutoVideo from '../../../components/App/TutoVideo';

export default {
  name: 'Tutoriels',

  mixins: [W3TelValidation],

  components: { TutoVideo },

  data () {
    return {}
  },

  mounted: function() {
    this.validate();
  }
};
</script>