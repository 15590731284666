const env = {};

env.config = {
  api: {
    host: `${process.env.VUE_APP_API_URL}/v1`,
    email: `${process.env.VUE_APP_API_EMAIL_URL}/api/v1`,
  },
  hcaptcha: {
    pk: `${process.env.VUE_APP_HCAPTCHA_PK}`,
  }
};

env.install = function (Vue) {
  Vue.prototype.$env = env.config;
}

module.exports = env;