<template>
  <v-carousel
    cycle
    hide-delimiters
  >
    <v-carousel-item v-for="slide in slides" :key="slide.link">
      <v-sheet
        height="100%"
      >
        <v-row
          class="fill-height pa-4"
          align="center"
          justify="center"
        >
          <v-col cols="1"></v-col>
          <v-col cols="5">
            <v-chip>{{ slide.chip }}</v-chip>
            
            <div class="text-h4 transition-swing mb-4 mt-4">
              {{ slide.title }}
            </div>
            <div class="text-subtitle-1 transition-swing mb-4 mt-4">
              {{ slide.sub }}
            </div>
            <v-btn color="#DF101E" class="white--text mt-2" :to="slide.link">En savoir plus</v-btn>
          </v-col>
          <v-col cols="5">
            <img :src="slide.img" style="max-width: 70%" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import carousel_1 from '../../../assets/images/carousel-1.png'
import carousel_2 from '../../../assets/images/carousel-2.png'
import carousel_3 from '../../../assets/images/carousel-3.png'

export default {
  name: 'HomeCarousel',

  data () {
    return {
      slides: [
        {
          chip: 'ESPACE CLIENT OCCICOM',
          title: 'Bienvenue sur votre Espace Client d\'aide OcciCom',
          sub: 'Vous trouverez ici toutes les informations et tutoriels nécessaires pour l\'utilisation de vos solutions OcciCom.',
          img: carousel_1,
          link: '/',
        },
        {
          chip: 'COMPAGNON OCCICOM',
          title: 'Maîtriser Compagnon, ma solution de pilotage de l\'ensemble de ma téléphonie pro',
          sub: 'Nous vous expliquons tout sur l\'utilisation de votre Compagnon pour piloter votre téléphonie depuis partout (PC/IOS/ANDROID).',
          img: carousel_2,
          link: '/compagnon',
        },
        {
          chip: 'ESPACE CLIENT OCCICOM',
          title: 'Gérer ma téléphonie pro depuis mon Espace Client.',
          sub: 'Apprenez à utiliser toutes les fonctionnalités que vous offre votre Espace client OcciCom pour configurer votre téléphonie pro.',
          img: carousel_3,
          link: '/espace_client',
        }
      ]
    }
  },
};
</script>